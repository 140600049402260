import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';

const content = {
  title: 'Cheppers x Szechenyi 2020',
  list: [
    'Kedvezményezett neve: Cheppers Szolgáltató Zártkörűen Működő Részvénytársaság',
    'Projekt címe: Modern üzleti és termelési kihívásokhoz való alkalmazkodását segítő fejlesztések támogatása a Cheppers Zrt.',
    'Szerződött támogatás összege: 23 133 902 .- Ft',
    'Támogatás mértéke (%-ban): 70%',
    'Projekt tervezett befejezési dátuma: 2022.03.30.',
    'Projekt azonosító száma: VEKOP-1.2.6-20-2020-01521',
  ],
  description:
    'A projekt megvalósításával a szolgáltatási porfóliónkat bővül olyan eszközök tervezésével, és gyártás ' +
    'előkészítésével, amely  új munkahelyeket teremthet vállalatunknál, másrészt pedig a jelenlegi piaci ' +
    'helyzetben más üzletágakban dolgozó kollégák megtartásában segíti. Illetve a tervezett nyílászárócserére ' +
    'megvalósításával  a jelenlegi megvalósítási helyszín elavult, rosszul szigetelő nyílászárókat lecserélésre ' +
    'kerülnek.',
};

function SzechenyiPage({ location }) {
  return (
    <PageLayout>
      <PageSEO title="Cheppers x Szechenyi 2020" description="Cheppers x Szechenyi 2020" location={location} />
      <Container fluid className="bg-shapes-banner px-0">
        <Container className="pt-9 pb-6">
          <Row className="align-items-center">
            <Col xs={12} lg={6}>
              <Col xs={12} className="overflow-hidden rounded-xl px-0 shadow-lg">
                <StaticImage src="./../images/szechenyi2020.jpg" alt="image" className="d-flex" />
              </Col>
            </Col>
            <Col xs={12} lg={6} className="mt-lg-0 order-lg-first mt-4">
              <Row>
                <Col xs={12} as="h2">
                  {content.title}
                </Col>
                <Col xs={12} className="mt-3">
                  <Row>
                    {content.list.map((item) => (
                      <Col key={item} xs={12} className="text-muted mt-2">
                        {item}
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6} className="text-muted mt-7">
              {content.description}
            </Col>
          </Row>
        </Container>
      </Container>
    </PageLayout>
  );
}

export default SzechenyiPage;
